import React from "react";
import fields from "./CustomField";
import widgets from "./CustomWidget";
import ArrayFieldTemplate from "./ArrayFieldTemplate";
import FieldTemplate from "./FieldTemplate";
import { transformErrors, validate } from "./QuoteFormError";

const theme = {
  fields,
  widgets,
  ArrayFieldTemplate,
  FieldTemplate,
  transformErrors,
  validate,
};

export default theme;
