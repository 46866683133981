import React, { useState, useContext, useCallback, useEffect } from "react";
import {
  Row,
  Label,
  Input,
  TextArea,
  Select,
  SelectOption,
  RadioBtn,
} from "../QuoteWidget";
import { Button, DatePicker, Upload, message, Tooltip } from "antd";
import * as Theme from "../../../Theme";
import moment from "moment";
import "moment/locale/zh-tw";
import locale from "antd/lib/date-picker/locale/zh_TW";
import {
  CloudUploadOutlined,
  CloseCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Context } from "../../../AppContext";
import { errorHandler } from "../../../errors";

function StringField(props) {
  const { schema, uiSchema, value, onChange } = props;
  const { title } = schema;
  const { ["ui:placeholder"]: placeholder } = uiSchema;

  return (
    <Input
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );
}

function TextareaWidget(props) {
  const { schema, uiSchema, value, onChange } = props;
  const { title } = schema;
  const { ["ui:placeholder"]: placeholder } = uiSchema;

  return (
    <TextArea
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  );
}

function FileWidget(props) {
  const { schema, uiSchema, value, onChange } = props;
  const { title } = schema;
  const { ["ui:placeholder"]: placeholder } = uiSchema;
  const [uploadData, setUploadData] = useState({});
  const app = useContext(Context);

  const getUploadUrl = useCallback(async file => {
    app.actions.setLoading(true);
    try {
      let resp = await app.actions.getUploadPresignUrl(file);
      setUploadData({
        url: resp.url,
        data: resp.fields,
        expected: resp.expected,
      });
    } catch (err) {
      message.warning("無法取得上傳路徑");
      app.actions.setLoading(false);
    }
  }, []);

  return (
    <div>
      <Row style={{ margin: 0, marginBottom: 10 }}>
        <Upload
          // accept="image/*"
          action={uploadData.url}
          data={uploadData.data}
          beforeUpload={getUploadUrl}
          showUploadList={false}
          onChange={async info => {
            if (info.file.status === "uploading") {
              // do progressing
              return;
            }
            if (info.file.status === "done") {
              app.actions.setLoading(false);
              onChange(uploadData.expected);
              return;
            }
          }}
        >
          <div
            style={{
              backgroundColor: Theme.colors.blueGreen,
              padding: "10px 20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <CloudUploadOutlined
              style={{ color: "white", fontSize: 25, marginRight: 7 }}
            />
            <div style={{ color: "white" }}>上傳檔案</div>
          </div>
        </Upload>

        <Tooltip
          title="如需上傳多個檔案請將文件壓縮為 RAR / ZIP 格式上傳。檔案支援 Adobe Illustrator / Adobe photoshop、JPG、PDF 等格式。請勿將印製圖檔置入 PPT / Word / Excel 等文字編輯檔格式中上傳提供。"
          color="#fff7d0"
          overlayInnerStyle={{ color: "#505050", padding: 12 }}
          placement="bottom"
        >
          <div
            style={{
              color: Theme.colors.text,
              textDecorationLine: "underline",
              cursor: "pointer",
              padding: 10,
            }}
          >
            上傳檔案條件說明
          </div>
        </Tooltip>
      </Row>
      {value && (
        <Row style={{ margin: 0, alignItems: "center" }}>
          <PaperClipOutlined
            style={{ color: "#707070", fontSize: 14, marginRight: 10 }}
          />
          <a href={value} target="_blank">
            {value}
          </a>
          <div style={{ cursor: "pointer" }} onClick={() => onChange(null)}>
            <CloseCircleOutlined
              style={{ color: "#707070", fontSize: 14, marginLeft: 10 }}
            />
          </div>
        </Row>
      )}
    </div>
  );
}

function DateWidget(props) {
  const { schema, uiSchema, value = "", onChange } = props;
  const { title } = schema;
  const { ["ui:placeholder"]: placeholder } = uiSchema;
  let _value = value;

  if (!value) {
    _value = new Date();
  } else if (value.indexOf("週") >= 0) {
    _value = value.slice(0, 10);
  }

  return (
    <DatePicker
      placeholder="選擇日期"
      // defaultValue={moment(value)}
      value={moment(_value)}
      locale={locale}
      format={value => value.format("YYYY/MM/DD - ddd")}
      style={{
        margin: "6px 0",
        padding: "8px 16px",
        backgroundColor: Theme.colors.sub,
        border: 0,
        borderBottom: "solid 1px #707070",
      }}
      onChange={(date, dateString) => {
        onChange(dateString);
      }}
    />
  );
}

function SelectWidget(props) {
  // console.log("select widget props", props);

  const { schema, uiSchema = {}, value, onChange, options } = props;
  const { title } = schema;
  const { ["ui:placeholder"]: placeholder } = uiSchema;

  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={_value => onChange(_value)}
    >
      {/* <SelectOption value="" key="empty" disabled>未選擇</SelectOption> */}
      {options.enumOptions.map((o, idx) => (
        <SelectOption value={o.value} key={idx}>
          {o.label}
        </SelectOption>
      ))}
    </Select>
  );
}

function RadioWidget(props) {
  // console.log("radio widget props", props);

  const { schema, uiSchema, value, onChange, options } = props;

  return (
    <RadioBtn.Group
      onChange={e => {
        let value = e.target.value;
        onChange(value);
      }}
      value={value}
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {options.enumOptions.map((o, key) => (
        <RadioBtn value={o.value} key={key}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: "1 0 80px" }}>{o.label}</div>
            {options.images && options.images[o.label] && (
              <img
                src={options.images[o.label]}
                alt={`options-${o.label}`}
                width="80"
                style={{ flexBasis: 80 }}
              />
            )}
          </div>
        </RadioBtn>
      ))}
    </RadioBtn.Group>
  );
}

function VarietyWidget(props) {
  const { schema, uiSchema, value, onChange, options } = props;

  useEffect(() => {
    console.log("chnaged", uiSchema?.type);
    onChange(value);
  }, [uiSchema?.type]);

  const type =
    Array.isArray(schema.enum) && uiSchema.type === "radio"
      ? "radio"
      : Array.isArray(schema.enum) && uiSchema.type === "select"
      ? "select"
      : "string";

  console.log("uiSchema.type", uiSchema.type);

  return type === "radio" ? (
    <RadioWidget {...props} />
  ) : (
    <StringField {...props} />
  );
}

const widgets = {
  BaseInput: StringField,
  TextareaWidget,
  FileWidget,
  DateWidget,
  SelectWidget,
  RadioWidget,
  VarietyWidget,
};

export default widgets;
