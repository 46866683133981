export default function serialize(data, _schema) {
  let result = "";
  let schema = { ..._schema };
  try {
    for (const key in data) {
      if (Object.hasOwnProperty.call(schema.properties, key)) {
        let value = data[key];
        const field_schema = schema.properties[key];

        if (Array.isArray(value)) {
          // array field
          value = value.map(
            (item, idx) =>
              `\n${idx + 1} . ${serialize(item, field_schema.items)}`
          );
        } else if (typeof value === "object") {
          // nested field recursively
          value = "\n" + serialize(value, field_schema);
        }

        // dependency fields spread on schema
        if (
          schema.dependencies &&
          Object.hasOwnProperty.call(schema.dependencies, key)
        ) {
          let depend = schema.dependencies[key].oneOf.find(
            d => data[key] === d.properties[key].enum[0]
          );

          if (depend) {
            schema.properties = {
              ...depend.properties,
              ...schema.properties,
            };
          }
        }

        result += `${field_schema.title} ： ${value} ${field_schema.unit ||
          ""}\n`;
      }
    }
  } catch (error) {
    console.warn(error);
    result += "解析錯誤\n";
  }

  return result;
}
