import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Tab } from "./QuoteWidget";
import Carousel from "nuka-carousel";
import LeftCircleOutlined from "@ant-design/icons/LeftCircleOutlined";
import RightCircleOutlined from "@ant-design/icons/RightCircleOutlined";
import * as Theme from "../../Theme";
import useDimension from "../../hooks/use-dimension";
const types = require("./types.json");

export default function TabBar(props) {
  const { type, onChange } = props;
  const [index, setIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { dimension, rwd } = useDimension();
  const maxWidth = useMemo(() => {
    return Number.parseInt(Theme.centerContentMaxWidth.replace("px"));
  }, []);

  const renderLeftIcon = useCallback(
    ({ previousSlide }) =>
      dimension.innerWidth > maxWidth ? (
        false
      ) : (
        <IconContainer onClick={previousSlide}>
          <LeftCircleOutlined style={{ color: "white", fontSize: 30 }} />
        </IconContainer>
      ),
    [dimension.innerWidth]
  );

  const renderRightIcon = useCallback(
    ({ nextSlide }) =>
      dimension.innerWidth > maxWidth ? (
        false
      ) : (
        <IconContainer onClick={nextSlide}>
          <RightCircleOutlined style={{ color: "white", fontSize: 30 }} />
        </IconContainer>
      ),
    [dimension.innerWidth]
  );

  return (
    <Wrapper>
      <Carousel
        width={"100%"}
        renderBottomCenterControls={() => false}
        renderCenterLeftControls={renderLeftIcon}
        renderCenterRightControls={renderRightIcon}
        slideIndex={index}
        afterSlide={setIndex}
        speed={500}
        cellSpacing={dimension.innerWidth < Theme.breakpoints.lg ? 15 : 28}
        slideWidth="140px"
      >
        {types.map((t, i) => {
          if (t.type === "印刷許願池") {
            return (
              <a
                href={t.link}
                target="_blank"
                rel="noreferrer"
                style={{
                  width: "140px",
                  height: "140px",
                  borderRadius: "4px",
                  display: "inline-block",
                  overflow: "hidden",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <img
                  src={isHovered && t.hoverImg ? t.hoverImg : t.img}
                  alt=""
                />
              </a>
            );
          }
          return (
            <Tab
              key={t.type}
              selected={t.type === type}
              onClick={() => onChange(t.type)}
            >
              <img src={t.img} alt="quote-type-icon" />
              {t.type}
            </Tab>
          );
        })}
      </Carousel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  & .slider-control-centerright,
  .slider-control-centerleft {
    top: 0 !important;
    bottom: 0 !important;
    transform: none !important;
  }
`;

const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 0px;
  background-color: #0005;
  width: 50px;
  height: 100%;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  :focus {
    outline: none;
  }
`;
