import React from "react";
import * as Ant from "antd";
import * as Theme from "../Theme";
import useDimension from "../hooks/use-dimension";

function CartBtn(props) {
  const { dimension } = useDimension();
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const mobile = dimension.innerWidth <= Theme.breakpoints.xs;

  return (
    <Ant.Button
      loading={props.loading}
      disabled={props.disabled}
      style={{
        flexGrow: 0,
        letterSpacing: 0.9,
        fontSize: 18,
        padding: "12px 0px",
        width: (() => {
          if (mobile) {
            return props.type == "primary" ? 240 : 120;
          } else if (pad) {
            return 350;
          } else {
            return 440;
          }
        })(),
        height: "auto",
        color: props.textColor,
        backgroundColor: props.bkgColor,
        ...(props.style || {}),
      }}
      onClick={props.action}
    >
      {props.text}
    </Ant.Button>
  );
}

export { CartBtn };
