import React, { useContext, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Row,
  Label,
  Input,
  Select,
  SelectOption,
  Plus,
  Minus,
  Hint,
  Size,
} from "../QuoteWidget";
import { Context } from "../../../AppContext";
import { ErrQuote, errorHandler } from "../../../errors";
import { Spin } from "antd";
import { LoadingOutlined, CaretDownOutlined } from "@ant-design/icons";
import AddressField from "../../../Components/AddressField";

function AddressInput(props) {
  const { schema, uiSchema, onChange, formData } = props;
  const { title } = schema;
  const { zipcode, county, town, address } = formData;
  const [loading, setLoading] = useState(false);
  const [counties, setCounties] = useState([]);
  const [towns, setTowns] = useState([]);

  const app = useContext(Context);

  const getZipCode = useCallback(async zipcode => {
    setLoading(true);
    try {
      let resp = await app.actions.getZipCode(zipcode);
      if (resp) {
        onChange({
          ...formData,
          zipcode,
          county: resp.countyName,
          town: resp.townName,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await app.actions.getCounties();
        setCounties(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    })();
  }, []);

  const getTowns = useCallback(async () => {
    if (county) {
      setLoading(true);
      try {
        let resp = await app.actions.getTowns(county);
        setTowns(resp);
      } catch (err) {
        errorHandler(err);
      }
      setLoading(false);
    }
  }, [props.formData?.county]);

  useEffect(() => {
    getTowns();
  }, [getTowns]);

  return (
    <AddressField
      onChange={values => {
        onChange({
          zipcode: values.zip_code,
          county: values.city,
          town: values.district,
          address: values.address,
        });
      }}
      zip_code={formData.zipcode}
      city={formData.county}
      district={formData.town}
      address={formData.address}
    />
  );

  return (
    <Row style={{ margin: 0 }}>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 16 }} />}
        style={{ alignSelf: "center", marginRight: 10 }}
      />
      <Input
        placeholder="郵遞區號"
        type="short"
        disabled={loading}
        value={zipcode}
        onChange={e => {
          let value = e.target.value;
          onChange({ ...formData, zipcode: value });
        }}
        onBlur={() => {
          if (/[0-9]{3}/g.test(zipcode)) {
            getZipCode(zipcode);
          }
        }}
        style={{ marginRight: 10 }}
      />

      <Select
        placeholder="縣市"
        value={county}
        onChange={value => {
          onChange({
            ...formData,
            county: value,
            town: undefined,
            zipcode: undefined,
          });
        }}
        disabled={loading}
        style={{ marginRight: 10 }}
      >
        {counties.map(c => (
          <SelectOption key={c.countyName} value={c.countyName}>
            {c.countyName}
          </SelectOption>
        ))}
      </Select>

      <Select
        placeholder="鄉鎮市區"
        value={town}
        onChange={value => {
          onChange({ ...formData, town: value });

          // set zipcode
          let instance = towns.find(t => t.townName === value);
          if (instance) {
            onChange({
              ...formData,
              town: value,
              zipcode: instance.zipCode,
            });
          }
        }}
        disabled={loading}
        style={{ marginRight: 10 }}
      >
        {towns.map(t => (
          <SelectOption key={t.townName} value={t.townName}>
            {t.townName}
          </SelectOption>
        ))}
      </Select>

      <Input
        placeholder="詳細地址路段"
        disabled={loading}
        value={address}
        type="long"
        onChange={e => onChange({ ...formData, address: e.target.value })}
      />
    </Row>
  );
}

const NumberInput = styled(Input).attrs({ type: "number" })`
  border-top: solid 1px #707070;
  border-bottom: solid 1px #707070;
  border-radius: 0;
  text-align: center;
  flex: 1;

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  -moz-appearance: textfield; /* Firefox */
`;

function NumberField(props) {
  // console.log("props", props);

  const { schema, uiSchema, onChange, formData: value } = props;
  const { title, multipleOf = 1 } = schema;

  const {
    ["ui:placeholder"]: placeholder,
    ["ui:options"]: options = {},
  } = uiSchema;

  return (
    <Row style={{ margin: 0, flexWrap: "nowrap" }}>
      <Minus
        style={{ margin: "6px 0 ", borderRadius: 0 }}
        onClick={() =>
          onChange(value - multipleOf >= 0 ? value - multipleOf : 0)
        }
      />
      <NumberInput
        // type="number"
        step={multipleOf}
        placeholder={placeholder}
        value={value}
        onChange={e => {
          let _value = e.target.value;
          onChange(Number(_value));
        }}
      />
      <Plus
        style={{ margin: "6px 0 ", borderRadius: 0 }}
        onClick={() => onChange(value + multipleOf)}
      />
    </Row>
  );
}

function SizeField(props) {
  // console.log("size props", props);

  const {
    schema,
    uiSchema,
    formData: values = {},
    name,
    onChange,
    options,
  } = props;

  const label = uiSchema["ui:options"]?.label === false ? "" : schema.title;

  return (
    <Size
      data={values}
      onChange={values => onChange(values)}
      options={{
        label,
        length: schema.properties?.hasOwnProperty("length"),
        width: schema.properties?.hasOwnProperty("width"),
        height: schema.properties?.hasOwnProperty("height"),
      }}
      units={{
        length: schema.properties["length"]?.unit,
        width: schema.properties["width"]?.unit,
        height: schema.properties["height"]?.unit,
      }}
    />
  );
}

function ProcessField(props) {
  // console.log("process props", props);

  const { schema, uiSchema, formData: values, name, onChange, options } = props;
  const {
    ["ui:placeholder"]: placeholder,
    ["ui:options"]: uiOptions = {},
  } = uiSchema;

  const label = uiOptions?.label === false ? "" : schema.title;

  return (
    <div>
      <Row style={{ margin: 0 }}>
        <Select
          placeholder={uiSchema.name["ui:placeholder"]}
          value={values["name"]}
          onChange={value =>
            onChange({
              ...values,
              name: value,
            })
          }
          style={{ marginRight: 20 }}
        >
          {schema.properties["name"].enum.map((key, idx) => (
            <SelectOption key={idx} value={key}>
              {key}
            </SelectOption>
          ))}
        </Select>
        <Size
          data={values}
          onChange={obj =>
            onChange({
              ...values,
              ...obj,
            })
          }
          options={{
            label,
            length: schema.properties?.hasOwnProperty("length"),
            width: schema.properties?.hasOwnProperty("width"),
            height: schema.properties?.hasOwnProperty("height"),
          }}
          units={{
            length: schema.properties["length"]?.unit,
            width: schema.properties["width"]?.unit,
            height: schema.properties["height"]?.unit,
          }}
        />
      </Row>
      <Row style={{ margin: 0 }}>
        <Input
          placeholder={uiSchema.note["ui:placeholder"]}
          value={values["note"]}
          onChange={e => onChange({ ...values, note: e.target.value })}
          style={{ flex: 1 }}
        />
        <Hint>{uiSchema.note["ui:help"]}</Hint>
      </Row>
    </div>
  );
}

const fields = {
  // StringField,
  AddressInput,
  NumberField,
  SizeField,
  ProcessField,
};

export default fields;
