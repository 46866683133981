import React, { useState, useContext, useCallback, Fragment } from "react";
import {
  Row,
  Label,
  Input,
  TextArea,
  Hint,
  Plus,
  Minus,
  Warning,
} from "../QuoteWidget";
import * as Theme from "../../../Theme";

export default function FieldTemplate(props) {
  // console.log("props", props);

  const {
    id,
    classNames,
    label,
    rawHelp,
    required,
    description,
    errors,
    rawErrors,
    children,
  } = props;

  return (
    <Row classNames={classNames}>
      <Label id={id} width="120" align="right">
        {label}
      </Label>
      <div style={{ flex: 1 }}>
        <Row style={{ margin: 0 }}>
          {children}
          {rawHelp && <Hint>{rawHelp}</Hint>}
        </Row>
        {rawErrors && Array.isArray(rawErrors) && (
          <Warning>{rawErrors.join(", ")}</Warning>
        )}
      </div>
    </Row>
  );
}

function UserFieldTemplate(props) {
  // console.log("props", props.id,  props);
  if (props.id.indexOf("name") > 0) {
    console.log("props", props);
  }

  const {
    id,
    classNames,
    label,
    rawHelp,
    required,
    description,
    errors,
    rawErrors,
    children,
  } = props;

  return (
    <Row classNames={classNames}>
      <Label id={id}>{label}</Label>
      <div style={{ flex: 1 }}>
        <Row style={{ margin: 0 }}>
          {children}
          {rawHelp && <Hint>{rawHelp}</Hint>}
        </Row>
        {rawErrors && Array.isArray(rawErrors) && (
          <Warning>{rawErrors.join(", ")}</Warning>
        )}
      </div>
    </Row>
  );
}

function NotFieldTemplate({ children, rawErrors, rawHelp, ...props }) {
  // console.log("props", props);
  return (
    <Fragment>
      {children}
      {rawHelp && <Hint>{rawHelp}</Hint>}
      {rawErrors && Array.isArray(rawErrors) && (
        <Warning>{rawErrors.join(", ")}</Warning>
      )}
    </Fragment>
  );
}

function ObjectFieldTemplate(props) {
  const { properties, rawErrors } = props;

  return (
    <div style={{ flex: 1 }}>
      {properties.map((element, idx) => (
        <Row
          style={{
            margin: 0,
            marginBottom: properties.length - 1 === idx ? 0 : 10,
          }}
          key={element.name}
        >
          {element.content}
        </Row>
      ))}
    </div>
  );
}

export { UserFieldTemplate, NotFieldTemplate, ObjectFieldTemplate };
