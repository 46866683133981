function transformErrors(errors) {
  console.log("transform errors", errors);

  return errors.map(err => {
    switch (err.name) {
      case "required":
        err.message = "此欄位必填";
        break;

      case "type":
        err.message = {
          "should be string": "請填入文字格式",
          "should be number": "請填入數字格式",
          "should be integer": "請填入整數格式",
          "should be boolean": "請確認是否勾選",
        }[err.message];
        break;

      case "oneOf":
        err.message = "不符合選項之格式";
        break;

      case "multipleOf":
        err.message = `必須是 ${err.params.multipleOf} 的倍數`;
        break;

      case "enum":
        err.message = "選項錯誤";
        break;

      case "minLength":
        err.message = `至少要${err.params.limit}個字`;
        break;

      case "pattern":
        err.message = "請輸入正確格式";
        break;

      default:
        break;
    }

    return err;
  });
}

function validate(formData, errors) {
  console.log("in validate error", errors);

  return errors;
}

export { transformErrors, validate };
