import type01 from "./quote_type_01";
import type02 from "./quote_type_02";
import type03 from "./quote_type_03";
import type04 from "./quote_type_04";
import type05 from "./quote_type_05";
import type06 from "./quote_type_06";
import type07 from "./quote_type_07";
import type08 from "./quote_type_08";

export default function getSpec(type) {
  const specList = {
    名片卡類: type01,
    海報: type02,
    貼紙: type03,
    "信封/公文封": type04,
    包裝類: type05,
    其他品項: type06,
    零售大量訂購: type07,
    書籍裝幀: type08,
  };
  return specList[type];
}
