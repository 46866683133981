import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { withTheme } from "@rjsf/core";
import theme from "./JsonSchemaForm/theme";
import { Divider, Radio } from "antd";
import { Row, Label, RadioBtn } from "./QuoteWidget";
import widgets from "./JsonSchemaForm/CustomWidget";
import {
  NotFieldTemplate,
  ObjectFieldTemplate,
} from "./JsonSchemaForm/FieldTemplate";
import serialize from "./JsonSchemaForm/serialize";
import { Context } from "../../AppContext";
import constants from "../../constants";
import getSpec from "./QuoteSpecs";

const Form = withTheme(theme);

export default function VariantSection(props) {
  const { type, data, onRef, onError, setData, setContent, setSubject } = props;
  const [products, setProducts] = useState([]);
  const [key, setKey] = useState(Date.now());
  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      try {
        let resp = await app.actions.getProducts({});
        setProducts(resp);
      } catch (err) {
        console.warn(err);
      }
    })();
  }, []);

  const { schema, uiSchema } = useMemo(() => {
    const spec = getSpec(type);
    let s = spec.schema;
    if (type === "零售大量訂購") {
      s.properties.product.enum = products
        .filter(p => p.file_upload === true)
        .map(p => p.name);
    }
    return { schema: s, uiSchema: spec.uiSchema };
  }, [products, type]);

  // console.log("data", data);
  // console.log("schema", schema);

  return (
    <Fragment>
      <Form
        key={key}
        ref={onRef}
        formData={data}
        schema={schema}
        uiSchema={uiSchema}
        widgets={widgets}
        onChange={({ formData }) => {
          // console.log("changed", formData);
          setData(formData);
        }}
        onSubmit={({ formData }) => {
          console.log("submited", formData);
          setContent(prev => ({
            ...prev,
            variant: serialize(formData, schema),
          }));
          setKey(Date.now());
          setSubject(
            `【${type}】${formData.name || formData.product || "諮詢表單"}`
          );
        }}
        onError={errors => {
          onError(errors);
          console.warn("errors", errors);
        }}
        showErrorList={constants.debug}
      >
        <button
          type="submit"
          style={{ display: constants.debug ? "inline" : "none" }}
        >
          測試
        </button>
      </Form>

      <Divider />

      <Row>
        <Label width="120" align="right" style={{ marginTop: 0 }}>
          您已選擇的產品
        </Label>
        <p style={{ color: "#707070", whiteSpace: "break-spaces" }}>
          {serialize(data, schema)}
        </p>
      </Row>

      <Divider />
    </Fragment>
  );
}
