import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import * as Theme from "../../Theme";
import useDimension from "../../hooks/use-dimension";
import { Context } from "../../AppContext";
import StaticImage from "../../Components/StaticImage";
import { Divider, Radio, Row as AntRow, Col as AntCol, message } from "antd";
import { CartBtn } from "../../Components/Cart.Button";
import { Title, Checkbox, Option, Row, Label, Input } from "./QuoteWidget";
import TabBar from "./TypeTabBar";
import UserSection, { USER_TYPE } from "./UserSection";
import VariantSection from "./VariantSection";
import { ErrQuote, errorHandler } from "../../errors";

const steps = [
  {
    title: "選擇印件規格",
    img: "/images/quote/quote_step_01.svg",
  },
  {
    title: "填寫聯絡資料",
    img: "/images/quote/quote_step_02.svg",
  },
  {
    title: "專人報價",
    img: "/images/quote/quote_step_03.svg",
  },
];

export default function QuotePage() {
  const [agree, setAgree] = useState(false);
  const [type, setType] = useState("名片卡類");
  const [user, setUser] = useState();
  const [subject, setSubject] = useState("");
  const [data, setData] = useState(null);
  const [content, setContent] = useState(""); // for email content;
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const userRef = useRef();
  const variantRef = useRef();
  let errors = useRef({ user: null, variant: null }).current;
  const app = useContext(Context);

  const [userType, setUserType] = useState(USER_TYPE.personal);

  const { rwd } = useDimension();
  const mobile = rwd === "pad" || rwd === "mobile";

  const submit = async () => {
    try {
      await userRef.current.submit();
      await variantRef.current.submit();

      console.log("errors", errors);
      if (errors["user"] || errors["variant"]) {
        throw new ErrQuote(
          `${errors.user ? "聯絡人 " : ""}${
            errors.variant ? "印件規格 " : ""
          }有錯誤，請填寫完整。`
        );
      }

      if (!agree) {
        throw new ErrQuote("請同意隱私權政策聲明");
      }

      console.log("complete");
      if (!content.user || !content.variant) {
        throw new ErrQuote("發生異常錯誤，請再點擊一次傳送！");
      }
      setSubmitBtnDisabled(true);
      await app.actions.contact({
        subject: `${subject}`,
        message:
          "\n\n 【 聯絡人資訊 】 \n" +
          content.user +
          `\n---------\n\n【 諮詢商品內容 】\n` +
          content.variant,
        name: user.name,
        phone: user.phone,
        local_phone: user.tel,
        email: user.email,
      });
      setSubmitBtnDisabled(false);
      message.success("成功寄出！");
    } catch (error) {
      console.log("submit caught", error);
      errorHandler(error);
    }
  };

  const reset = useCallback(() => {
    setType("名片卡類");
    setUserType(USER_TYPE.personal);
    setData(null);
    setUser(null);
    errors = { user: null, variant: null };
  }, []);

  return (
    <Wrapper isMobile={mobile}>
      <div className="banner">
        <div className="img-container">
          <StaticImage
            filename="quote_banner.webp"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <div className="banner-tag">
            <div>Quote / 印刷諮詢</div>
          </div>
        </div>
      </div>

      <div style={{ height: 40 }} />

      <div className="center-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#707070",
            marginBottom: 40,
          }}
        >
          <h2 style={{ color: "#707070", marginBottom: 20 }}>印件製作諮詢</h2>
          <div
            className="row"
            style={{
              display: "flex",
              marginBottom: 20,
              flexDirection: rwd === "mobile" ? "column" : "row",
            }}
          >
            {steps.map((step, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: rwd === "mobile" ? "column" : "row",
                  alignItems: "center",
                }}
              >
                <StepItem rwd={rwd}>
                  <div className="quote-img-wrapper">
                    <img className="quote-img" src={step.img} alt="" />
                  </div>
                  <p>{step.title}</p>
                </StepItem>
                {idx !== steps.length - 1 && (
                  <img
                    src={
                      rwd === "mobile"
                        ? "/images/about/arrow-down.png"
                        : "/images/about/arrow.png"
                    }
                    width={rwd === "mobile" ? "8" : "35"}
                    height={rwd === "mobile" ? "35" : "auto"}
                    style={
                      rwd === "mobile"
                        ? { marginLeft: 90 }
                        : { marginBottom: 50 }
                    }
                  />
                )}
              </div>
            ))}
          </div>

          <div style={{ marginBottom: 15 }}>
            感官提供現場印刷諮詢服務，有諮詢需求者請先填寫印件需求單，將有專人為您服務。
          </div>
          <p style={{ fontSize: 12, marginBottom: 8 }}>
            填寫印件需求：1.印件規格 /
            2.個人資料，填寫印件需求後，報價需要約2-3個工作天（不含假日），我們可能會視您的印刷需求進行評估與調整建議。製作需求確認無誤後，將請您簽署報價單後進行審稿作業，
          </p>
          <p style={{ fontSize: 12 }}>
            以確認稿件是否符合印刷規範。完成審稿後將開始印製並交貨，印製期會依印件狀況而有所不同，交貨時間若有指定亦可於印件需求中事先提出，以免影響到您自身權益。
          </p>
        </div>
      </div>

      <div className="center-content">
        <Title>Step.1 選擇及填寫印件規格</Title>
        <TabBar
          type={type}
          onChange={type => {
            setType(type);
            setData(null);
          }}
        />
        <Divider />

        <VariantSection
          type={type}
          data={data}
          setData={data => {
            setData(data);
            errors.variant = null;
          }}
          onRef={ref => (variantRef.current = ref)}
          onError={err => (errors.variant = err)}
          setContent={setContent}
          setSubject={setSubject}
        />
      </div>

      <div className="center-content">
        <Title>Step.2 填寫聯絡人資訊</Title>
        <UserSection
          type={userType}
          setUserType={setUserType}
          user={user}
          setUser={data => {
            setUser(data);
            errors.user = null;
          }}
          onRef={ref => (userRef.current = ref)}
          onError={err => (errors.user = err)}
          setContent={setContent}
        />
      </div>

      <div className="center-content">
        <Divider />
        <div style={{ display: "flex" }}>
          <Checkbox
            style={{ marginRight: 10 }}
            onChange={e => setAgree(e.target.checked)}
            // checked={}
          >
            <div>
              <div
                style={{
                  marginBottom: 10,
                  color: Theme.colors.main,
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                隱私權政策聲明
              </div>
              <div
                style={{
                  whiteSpace: "break-spaces",
                  fontSize: 14,
                  color: "#717071",
                  lineHeight: "28px",
                }}
              >
                {`我同意「感官文化印刷」(以下簡稱本網站)，對於個人資料的收集。為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的`}
                <Link
                  to="/faq"
                  target="_blank"
                  style={{ textDecorationLine: "underline" }}
                >
                  隱私權政策聲明
                </Link>
                {`，請您詳閱連結內容以保障您的權益，如果您對我們如何蒐集、使用或揭露您的個人資料存有疑問或顧慮，請寄送郵件至service@ssprint.com.tw
`}
              </div>
            </div>
          </Checkbox>
        </div>
      </div>

      <div className="center-content">
        <Divider />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#f2f2f2",
            padding: 30,
            marginBottom: 30,
          }}
        >
          <h2>關於INFO</h2>
          <p style={{ fontSize: 18 }}>02-2736-6566</p>
          <p>週一至週五上午10點至下午7點（例假日休息）</p>
          <p>新北市中和區板南路496號3樓</p>

          <p>Email | service@ssprint.com.tw</p>
          <p>
            Line ID |{" "}
            <a
              target="_blank"
              href="https://page.line.me/ssprint?openQrModal=true"
              rel="noreferrer"
            >
              @ssprint
            </a>
          </p>
        </div>
      </div>

      <div className="center-content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 70,
          }}
        >
          <CartBtn
            bkgColor={Theme.colors.lightGreen}
            text="刪除資料重新填寫"
            textColor={Theme.colors.textReverse}
            action={reset}
            style={{
              fontSize: rwd === "mobile" ? 12 : 18,
            }}
          />
          <div style={{ flex: 1, flexBasis: rwd === "mobile" ? 5 : 20 }}></div>
          <CartBtn
            type="primary"
            text="確認填寫資料，傳送諮詢單"
            textColor={Theme.colors.textReverse}
            bkgColor={Theme.colors.main}
            action={submit}
            style={{
              fontSize: rwd === "mobile" ? 12 : 18,
            }}
            disabled={submitBtnDisabled}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .banner {
    background-color: #000;
    & > .img-container {
      position: relative;
      height: ${props => (props.isMobile ? "250px" : "270px")};
      max-width: ${Theme.centerContentMaxWidth};
      margin: 0px auto;
      & > .banner-tag {
        ${Theme.bannerTagStyle}
        background-color: #3c3c3c;
        & > div {
          color: #ffffff;
        }
      }
    }
  }
  & > .center-content {
    max-width: ${Theme.centerContentMaxWidth};
    margin: 0px auto;
    & .labels-row {
      display: flex;
      justify-content: space-between;
    }
    & > .header {
      & > .title {
        ${Theme.textTitleStyle}
        margin-top: 38px;
        text-align: center;
      }
      & > .subtitle {
        ${Theme.textContentStyle}
        margin-top: 10px;
        text-align: center;
      }
    }
    & > .collapse-list {
      padding: ${props => (props.isMobile ? Theme.rwdPadding.outer : 0)};
      margin: 40px 0px;
    }
    & > .rwd-wrapper {
      max-width: 600px;
      margin: 0 auto;
      padding: ${props =>
        props.isMobile ? Theme.rwdPadding.outer : Theme.rwdPadding.large};
    }

    @media screen and (max-width: ${Theme.centerContentMaxWidth}) {
      margin: 0 10px;
    }
  }
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: ${props => (props.rwd === "mobile" ? "row" : "column")};
  align-items: center;
  text-align: center;
  padding: ${props => (props.rwd === "mobile" ? "0px" : "10px")};
  & > p {
    margin-top: 13px;
    margin-left: ${props => (props.rwd === "mobile" ? "15px" : "0px")};
    min-width: 84px;
  }
  & > .quote-img-wrapper {
    position: relative;
    width: ${props => (props.rwd === "desktop" ? "100px" : "80px")};
    height: ${props => (props.rwd === "desktop" ? "100px" : "80px")};
    & > .quote-img {
      background-size: contain;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: ${props => `url(${props.img})`};
    }
  }
`;
