import React, { useState, useContext, useCallback } from "react";
import { Row, Label, Input, TextArea, Hint, Plus, Minus } from "../QuoteWidget";
import { Button, DatePicker, Upload, message, Tooltip } from "antd";
import * as Theme from "../../../Theme";

function Item(props) {
  const { children, index, hasRemove, disabled, onDropIndexClick } = props;
  return (
    <Row style={{ margin: 0, marginBottom: 15 }}>
      {children}
      {hasRemove && (
        <Minus
          disabled={disabled}
          onClick={onDropIndexClick(index)}
          style={{ margin: "6px 20px" }}
        />
      )}
    </Row>
  );
}

export default function ArrayFieldTemplate(props) {
  const { items, onAddClick, canAdd, title, uiSchema } = props;
  const { ["ui:options"]: options = {} } = uiSchema;

  return (
    <div>
      {items.map(item => (
        <Item key={item.key} {...item} />
      ))}
      {canAdd && <Plus onClick={onAddClick} style={{ margin: "6px 0" }} />}
    </div>
  );
}
