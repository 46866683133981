import {
  NotFieldTemplate,
  ObjectFieldTemplate,
} from "../JsonSchemaForm/FieldTemplate";

const spec = {
  name: "貼紙",
  schema: {
    type: "object",
    required: ["name", "side"],
    properties: {
      name: {
        title: "製作品名*",
        type: "string",
        default: "",
        minLength: 1,
      },
      side: {
        title: "印刷面*",
        type: "string",
        enum: ["雙面印刷", "單面印刷"],
      },
      print_method: {
        title: "印刷方式",
        type: "object",
        properties: {
          name: {
            title: "印刷",
            type: "string",
            default: "獨立版印刷",
            enum: ["獨立版印刷", "數位印刷", "藝術直噴", "網版印刷"],
          },
          detail: {
            title: "詳細",
          },
          // "material": {
          //   "title": "紙材選擇",
          // }
        },
        dependencies: {
          name: {
            oneOf: [
              {
                properties: {
                  name: {
                    enum: ["獨立版印刷"],
                  },
                  detail: {
                    type: "string",
                    enum: [
                      "CMYK(一般四彩色印刷)",
                      "黑白",
                      "特別色(Pantone色）",
                      "CMYK+特別色",
                    ],
                  },
                  material: {
                    title: "紙材選擇",
                    type: "string",
                    enum: [
                      "白色銅版紙",
                      "防水合成紙",
                      "透明合成紙",
                      "白色模造紙",
                      "牛皮紙",
                      "日本和紙",
                    ],
                  },
                },
              },
              {
                properties: {
                  name: {
                    enum: ["數位印刷"],
                  },
                  detail: {
                    type: "string",
                    enum: [
                      "CMYK(一般四彩色印刷)",
                      "黑白",
                      "印金 / 印銀 / 印白",
                      "CMYK+印金 / 印銀 / 印白",
                    ],
                  },
                  material: {
                    title: "紙材選擇",
                    type: "string",
                    enum: [
                      "白色銅版紙",
                      "防水合成紙",
                      "透明合成紙",
                      "白色模造紙",
                      "牛皮紙",
                      "日本和紙",
                    ],
                  },
                },
              },
              {
                properties: {
                  name: {
                    enum: ["藝術直噴"],
                  },
                  detail: {
                    type: "string",
                    enum: [
                      "8色廣色域藝術直噴",
                      "8色廣色域藝術直噴+光油 or 白墨",
                      "8色廣色域藝術直噴+光油 & 白墨",
                    ],
                  },
                  material: {
                    title: "紙材選擇",
                    type: "string",
                    enum: [
                      "白色銅版紙",
                      "防水合成紙",
                      "透明合成紙",
                      "白色模造紙",
                      "牛皮紙",
                      "日本和紙",
                    ],
                  },
                },
              },
              {
                properties: {
                  name: {
                    enum: ["網版印刷"],
                  },
                  detail: {
                    title: "自訂色數",
                    type: "string",
                  },
                },
              },
            ],
          },
        },
      },
      shape: {
        title: "貼紙形狀",
        type: "string",
        enum: ["圓形", "矩形", "圓角矩形", "自訂形狀 (於備註說明)"],
        default: "圓形",
      },
      size: {
        title: "成品尺寸",
        type: "object",
        properties: {
          type: {
            title: "尺寸",
            type: "string",
            enum: ["自訂尺寸"],
            default: "自訂尺寸",
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: {
                    enum: ["自訂尺寸"],
                  },
                  detail: {
                    title: "詳細",
                    type: "object",
                    properties: {
                      length: {
                        title: "長",
                        type: "integer",
                        default: 0,
                        unit: "mm",
                      },
                      width: {
                        title: "寬",
                        type: "integer",
                        default: 0,
                        unit: "mm",
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      },
      process: {
        title: "加工選項",
        type: "array",
        items: {
          type: "object",
          properties: {
            name: {
              title: "加工選項",
              type: "string",
              enum: [
                "燙箔 (燙金/燙銀/特殊箔）",
                "打凹凸",
                "上膜 (霧膜/亮膜)",
                "壓線",
                "導圓角(導圓尺寸5R)",
                "導圓角(導圓尺寸7.5R)",
                "軋型刀模",
                "其他加工(自填)",
                "局部光",
                "上水光",
                "變動資料 (流水號/條碼)",
                "自訂加工",
              ],
              default: "燙箔 (燙金/燙銀/特殊箔）",
            },
            length: {
              title: "長",
              type: "integer",
              default: 0,
              unit: "mm",
              pattern: "^\\d*$",
            },
            width: {
              title: "寬",
              type: "integer",
              default: 0,
              unit: "mm",
              pattern: "^\\d*$",
            },
            note: {
              title: "加工備註",
              type: "string",
            },
          },
        },
      },
      attachment: {
        title: "上傳檔案",
        type: "string",
      },
      quantity: {
        title: "製作數量",
        type: "number",
        default: 1,
        unit: "份",
      },
      budget: {
        title: "製作預算",
        type: "string",
        minLength: 1,
      },
      expect_time: {
        title: "預計收到成品時間",
        type: "string",
      },
      note: {
        title: "製作備註",
        type: "string",
      },

      // quote_way: {
      //   title: "報價方式",
      //   type: "string",
      //   enum: ["想先簡單了解報價", "需索取正式報價單"],
      // },
    },
  },
  uiSchema: {
    "ui:FieldTemplate": NotFieldTemplate,
    "ui:options": {
      label: false,
    },
    side: {
      "ui:placeholder": "單雙面",
    },
    size: {
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      type: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
      detail: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:field": "SizeField",
        dimension: 2,
      },
      name: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
    },
    print_method: {
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      name: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:help":
          "獨立版（開版印刷）/ 數位印刷（免開版）/ 藝術直噴（UV直噴）/ 網版印刷／大圖輸出請選擇「其他品項」",
      },
      detail: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:widget": "VarietyWidget",
        "ui:placeholder": "自定色數",
        type: "radio",
      },
      material: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:placeholder": "紙材選擇",
      },
    },
    name: {
      "ui:placeholder": "名稱",
    },
    note: {
      "ui:placeholder":
        "若上列項目選單未能讓您詳盡說明製作需求，如：特殊材質選擇／書籍類特殊插頁需求／加工方式 / 款式等，皆請於該欄位填寫備註。",
      "ui:widget": "textarea",
      "ui:options": {
        rows: 3,
      },
    },
    quantity: {
      "ui:help":
        "請提供製作「總數」，若有不同款式需有個別指定數量，請於「製作備註」另外說明。",
    },
    expect_time: {
      "ui:widget": "date",
      "ui:help":
        "建議若為指定時間需使用，如活動、展覽等，請於備註另外說明活動舉辦時間。請注意！實際交期時間將依正式製作需求評估後確認！",
    },
    attachment: {
      "ui:widget": "file",
    },
    process: {
      items: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:field": "ProcessField",
        name: {
          "ui:placeholder": "請選擇加工選項",
        },
        note: {
          "ui:placeholder": "加工備註：請輸入加工尺寸範圍或備註",
          "ui:help":
            "請填寫該加工面積尺寸及加工位置（建議可附上參考圖片），若您的加工範圍為印刷品整面（如上膜）則不用填寫尺寸。若燙箔已知燙箔色號請填寫加工備註；壓線建議可備註印件展開及完成尺寸以供確認。若導圓角不需填寫尺寸。",
        },
      },
    },
    quote_way: {
      "ui:widget": "radio",
    },
    budget: {
      "ui:placeholder": "請輸入您的預計製作預算",
      "ui:help":
        "若為單件預算請寫「單件預算 / 單位」；若為總預算請寫「總預算：」。",
    },
  },
};

export default spec;
