import {
  NotFieldTemplate,
  ObjectFieldTemplate,
} from "../JsonSchemaForm/FieldTemplate";

const spec = {
  name: "海報",
  schema: {
    type: "object",
    required: ["name", "side"],
    properties: {
      name: {
        title: "製作品名*",
        type: "string",
        default: "",
        minLength: 1,
      },
      side: {
        title: "印刷面*",
        type: "string",
        enum: ["雙面印刷", "單面印刷"],
      },
      print_method: {
        title: "印刷方式",
        type: "object",
        properties: {
          name: {
            title: "印刷",
            type: "string",
            default: "獨立版印刷",
            enum: ["獨立版印刷", "數位印刷", "藝術直噴", "網版印刷"],
          },
          detail: {
            title: "詳細",
          },
          // "material": {
          //   "title": "紙材選擇",
          // }
        },
        dependencies: {
          name: {
            oneOf: [
              {
                properties: {
                  name: {
                    enum: ["獨立版印刷"],
                  },
                  detail: {
                    type: "string",
                    enum: [
                      "CMYK(一般四彩色印刷)",
                      "黑白",
                      "特別色(Pantone色）",
                      "CMYK+特別色",
                    ],
                  },
                  material: {
                    title: "紙材選擇",
                    type: "string",
                    enum: [
                      "松韻紙 FKD1201-128g",
                      "松韻紙 FKD1202-128g",
                      "松韻紙 FKD1203-128g",
                      "松韻紙 FKD0801-81g",
                      "松韻紙 FKD0803-81g",
                      "松韻紙 FKD0804-81g",
                      "松韻紙 FKD0805-81g",
                      "凝雪映畫 100g",
                      "凝雪映畫 120g",
                      "凝雪映畫 200g",
                      "凝雪映畫 240g",
                      "沐光紙 120g",
                      "沐光紙 240g",
                      "新浪潮VV 122g",
                      "新浪潮VV 227g",
                      "新浪潮VG 151g",
                      "新浪潮VG 227g",
                      "新浪潮VF 128g",
                      "新浪潮VF 209g",
                      "新浪潮VN 122g",
                      "新浪潮VN 227g",
                      "儷紋紙 200g",
                      "日本環保五感紙-細目 180g",
                      "日本環保五感紙-荒目 186g",
                      "義大利水彩紙160g",
                      "象牙卡 220g - 白",
                      "象牙卡 270g - 白",
                      "細紋紙 220g - 白",
                      "萊妮紙 270g - 白",
                      "安格紙 270g - 白",
                      "日本NT元素紙",
                      "草香紙 RC1402 120g",
                      "羊毛紙 250p",
                      "北歐輕塗紙 PA0703 70g",
                      "霓彩紙 IA1600 135p",
                      "再生棉絮紙 FL2401 240g",
                      "新百代 300g - 自訂顏色請備註",
                      "百代紙 #01 純白 300g",
                      "百代紙 #46 鵝卵石 300g",
                      "百代紙 #50 鋼鐵灰 300g",
                      "百代紙 #51 信天翁 300g",
                      "梧桐紙 100g",
                      "梧桐媚紙 128g",
                      "超感輕塗紙 白 128g",
                      "超感輕塗紙 米白 128g",
                      "Matter 馬鈴薯紙 黑 135g",
                      "Matter 馬鈴薯紙 白 135g",
                      "麗絲坦紋紙-2 超白 100g",
                      "櫻桃丸紙 92g",
                      "特麗紙 #01 140g",
                      "特麗紙 #02 140g",
                      "乳香回韻紙 55g",
                      "文平紙 10001-110g 白",
                      "文平紙 10002-110g 米",
                      "典藏卡 04B",
                      "典藏卡 13A",
                      "典藏卡 17B",
                      "高級維妙紙 4902 64g",
                      "雪銅 100g",
                      "雪銅 120g",
                      "雪銅 150g",
                      "雪面銅西 200g",
                      "雪面銅西 250g",
                      "雪面銅西 300g",
                      "雪面銅西 350g",
                      "高白環保道林 100g",
                      "高白環保道林 120g",
                      "米色環保道林 100g",
                      "全木道林 126g",
                      "全木道林 158g",
                      "雪白畫刊 100g",
                      "雪白畫刊 120g",
                      "光鑽白牛皮(單光白牛皮) 13T",
                      "光鑽白牛皮(單光白牛皮) 15T",
                      "堅美萊特 13T",
                      "堅美萊特 15T",
                      "鄉村紙 250g",
                      "艾美卡 245g",
                      "FSC全木漿黑卡 300g",
                      "FSC全木漿黑卡 350g",
                      "大亞法蘭紙 300g - 深藍",
                      "佳域-瑪芬紙 314g",
                      "環保波絲紙 290g",
                      "馬諦斯紙 300g - 自訂顏色請備註",
                      "德國棉卡 240g",
                      "羅莎紙244g-白",
                      "羅莎紙244g-黑",
                      "羅莎紙320g-白",
                      "羅莎紙320g-黑",
                    ],
                  },
                },
              },
              {
                properties: {
                  name: {
                    enum: ["數位印刷"],
                  },
                  detail: {
                    type: "string",
                    enum: [
                      "CMYK(一般四彩色印刷)",
                      "黑白",
                      "印金 / 印銀 / 印白",
                      "CMYK+印金 / 印銀 / 印白",
                    ],
                  },
                  material: {
                    title: "紙材選擇",
                    type: "string",
                    enum: [
                      "松韻紙 FKD1201-128g",
                      "松韻紙 FKD1202-128g",
                      "松韻紙 FKD1203-128g",
                      "松韻紙 FKD0801-81g",
                      "松韻紙 FKD0803-81g",
                      "松韻紙 FKD0804-81g",
                      "松韻紙 FKD0805-81g",
                      "凝雪映畫 100g",
                      "凝雪映畫 120g",
                      "凝雪映畫 200g",
                      "凝雪映畫 240g",
                      "沐光紙 120g",
                      "沐光紙 240g",
                      "新浪潮VV 122g",
                      "新浪潮VV 227g",
                      "新浪潮VG 151g",
                      "新浪潮VG 227g",
                      "新浪潮VF 128g",
                      "新浪潮VF 209g",
                      "新浪潮VN 122g",
                      "新浪潮VN 227g",
                      "儷紋紙 200g",
                      "日本環保五感紙-細目 180g",
                      "日本環保五感紙-荒目 186g",
                      "義大利水彩紙160g",
                      "象牙卡 220g - 白",
                      "象牙卡 270g - 白",
                      "細紋紙 220g - 白",
                      "萊妮紙 270g - 白",
                      "安格紙 270g - 白",
                      "日本NT元素紙",
                      "草香紙 RC1402 120g",
                      "羊毛紙 250p",
                      "北歐輕塗紙 PA0703 70g",
                      "霓彩紙 IA1600 135p",
                      "再生棉絮紙 FL2401 240g",
                      "新百代 300g - 自訂顏色請備註",
                      "百代紙 #01 純白 300g",
                      "百代紙 #46 鵝卵石 300g",
                      "百代紙 #50 鋼鐵灰 300g",
                      "百代紙 #51 信天翁 300g",
                      "梧桐紙 100g",
                      "梧桐媚紙 128g",
                      "超感輕塗紙 白 128g",
                      "超感輕塗紙 米白 128g",
                      "Matter 馬鈴薯紙 黑 135g",
                      "Matter 馬鈴薯紙 白 135g",
                      "麗絲坦紋紙-2 超白 100g",
                      "櫻桃丸紙 92g",
                      "特麗紙 #01 140g",
                      "特麗紙 #02 140g",
                      "乳香回韻紙 55g",
                      "文平紙 10001-110g 白",
                      "文平紙 10002-110g 米",
                      "典藏卡 04B",
                      "典藏卡 13A",
                      "典藏卡 17B",
                      "高級維妙紙 4902 64g",
                      "雪銅 100g",
                      "雪銅 120g",
                      "雪銅 150g",
                      "雪面銅西 200g",
                      "雪面銅西 250g",
                      "雪面銅西 300g",
                      "雪面銅西 350g",
                      "高白環保道林 100g",
                      "高白環保道林 120g",
                      "米色環保道林 100g",
                      "全木道林 126g",
                      "全木道林 158g",
                      "雪白畫刊 100g",
                      "雪白畫刊 120g",
                      "光鑽白牛皮(單光白牛皮) 13T",
                      "光鑽白牛皮(單光白牛皮) 15T",
                      "堅美萊特 13T",
                      "堅美萊特 15T",
                      "鄉村紙 250g",
                      "艾美卡 245g",
                      "FSC全木漿黑卡 300g",
                      "FSC全木漿黑卡 350g",
                      "大亞法蘭紙 300g - 深藍",
                      "佳域-瑪芬紙 314g",
                      "環保波絲紙 290g",
                      "馬諦斯紙 300g - 自訂顏色請備註",
                      "德國棉卡 240g",
                      "羅莎紙244g-白",
                      "羅莎紙244g-黑",
                      "羅莎紙320g-白",
                      "羅莎紙320g-黑",
                    ],
                  },
                },
              },
              {
                properties: {
                  name: {
                    enum: ["藝術直噴"],
                  },
                  detail: {
                    type: "string",
                    enum: [
                      "8色廣色域藝術直噴",
                      "8色廣色域藝術直噴+光油 or 白墨",
                      "8色廣色域藝術直噴+光油 & 白墨",
                    ],
                  },
                  material: {
                    title: "紙材選擇",
                    type: "string",
                    enum: [
                      "松韻紙 FKD1201-128g",
                      "松韻紙 FKD1202-128g",
                      "松韻紙 FKD1203-128g",
                      "松韻紙 FKD0801-81g",
                      "松韻紙 FKD0803-81g",
                      "松韻紙 FKD0804-81g",
                      "松韻紙 FKD0805-81g",
                      "凝雪映畫 100g",
                      "凝雪映畫 120g",
                      "凝雪映畫 200g",
                      "凝雪映畫 240g",
                      "沐光紙 120g",
                      "沐光紙 240g",
                      "新浪潮VV 122g",
                      "新浪潮VV 227g",
                      "新浪潮VG 151g",
                      "新浪潮VG 227g",
                      "新浪潮VF 128g",
                      "新浪潮VF 209g",
                      "新浪潮VN 122g",
                      "新浪潮VN 227g",
                      "儷紋紙 200g",
                      "日本環保五感紙-細目 180g",
                      "日本環保五感紙-荒目 186g",
                      "義大利水彩紙160g",
                      "象牙卡 220g - 白",
                      "象牙卡 270g - 白",
                      "細紋紙 220g - 白",
                      "萊妮紙 270g - 白",
                      "安格紙 270g - 白",
                      "日本NT元素紙",
                      "草香紙 RC1402 120g",
                      "羊毛紙 250p",
                      "北歐輕塗紙 PA0703 70g",
                      "霓彩紙 IA1600 135p",
                      "再生棉絮紙 FL2401 240g",
                      "新百代 300g - 自訂顏色請備註",
                      "百代紙 #01 純白 300g",
                      "百代紙 #46 鵝卵石 300g",
                      "百代紙 #50 鋼鐵灰 300g",
                      "百代紙 #51 信天翁 300g",
                      "梧桐紙 100g",
                      "梧桐媚紙 128g",
                      "超感輕塗紙 白 128g",
                      "超感輕塗紙 米白 128g",
                      "Matter 馬鈴薯紙 黑 135g",
                      "Matter 馬鈴薯紙 白 135g",
                      "麗絲坦紋紙-2 超白 100g",
                      "櫻桃丸紙 92g",
                      "特麗紙 #01 140g",
                      "特麗紙 #02 140g",
                      "乳香回韻紙 55g",
                      "文平紙 10001-110g 白",
                      "文平紙 10002-110g 米",
                      "典藏卡 04B",
                      "典藏卡 13A",
                      "典藏卡 17B",
                      "高級維妙紙 4902 64g",
                      "雪銅 100g",
                      "雪銅 120g",
                      "雪銅 150g",
                      "雪面銅西 200g",
                      "雪面銅西 250g",
                      "雪面銅西 300g",
                      "雪面銅西 350g",
                      "高白環保道林 100g",
                      "高白環保道林 120g",
                      "米色環保道林 100g",
                      "全木道林 126g",
                      "全木道林 158g",
                      "雪白畫刊 100g",
                      "雪白畫刊 120g",
                      "光鑽白牛皮(單光白牛皮) 13T",
                      "光鑽白牛皮(單光白牛皮) 15T",
                      "堅美萊特 13T",
                      "堅美萊特 15T",
                      "鄉村紙 250g",
                      "艾美卡 245g",
                      "FSC全木漿黑卡 300g",
                      "FSC全木漿黑卡 350g",
                      "大亞法蘭紙 300g - 深藍",
                      "佳域-瑪芬紙 314g",
                      "環保波絲紙 290g",
                      "馬諦斯紙 300g - 自訂顏色請備註",
                      "德國棉卡 240g",
                      "羅莎紙244g-白",
                      "羅莎紙244g-黑",
                      "羅莎紙320g-白",
                      "羅莎紙320g-黑",
                      "木板",
                      "壓克力",
                      "自備材質",
                      "透明片0.1",
                      "透明片0.2",
                      "透明片0.35 ( 獨立/直噴)",
                    ],
                  },
                },
              },
              {
                properties: {
                  name: {
                    enum: ["網版印刷"],
                  },
                  detail: {
                    title: "自訂色數",
                    type: "string",
                  },
                },
              },
            ],
          },
        },
      },
      size: {
        title: "成品尺寸",
        type: "object",
        properties: {
          type: {
            title: "尺寸",
            type: "string",
            enum: [
              "A0 - 841mm x 1189mm",
              "A1 - 594mm x 841mm",
              "A2 - 420mm x 594mm",
              "A3 - 297mm x 420mm",
              "A4 - 210mm x 297mm",
              "A5 - 148mm x 210mm",
              "A6 - 105mm x 148mm",
              "B1 - 707mm x 1000mm",
              "B2 - 500mm x 707mm",
              "B3 - 353mm x 500mm",
              "B4 - 250mm x 353mm",
              "B5 - 176mm x 250mm",
              "自訂尺寸",
            ],
            default: "自訂尺寸",
          },
        },
        dependencies: {
          type: {
            oneOf: [
              {
                properties: {
                  type: {
                    enum: [
                      "A0 - 841mm x 1189mm",
                      "A1 - 594mm x 841mm",
                      "A2 - 420mm x 594mm",
                      "A3 - 297mm x 420mm",
                      "A4 - 210mm x 297mm",
                      "A5 - 148mm x 210mm",
                      "A6 - 105mm x 148mm",
                      "B1 - 707mm x 1000mm",
                      "B2 - 500mm x 707mm",
                      "B3 - 353mm x 500mm",
                      "B4 - 250mm x 353mm",
                      "B5 - 176mm x 250mm",
                    ],
                  },
                },
              },
              {
                properties: {
                  type: {
                    enum: ["自訂尺寸"],
                  },
                  detail: {
                    title: "自訂尺寸",
                    type: "object",
                    properties: {
                      length: {
                        title: "長",
                        type: "integer",
                        default: 0,
                        unit: "mm",
                      },
                      width: {
                        title: "寬",
                        type: "integer",
                        default: 0,
                        unit: "mm",
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      },
      process: {
        title: "加工選項",
        type: "array",
        items: {
          type: "object",
          properties: {
            name: {
              title: "加工選項",
              type: "string",
              enum: [
                "燙箔 (燙金/燙銀/特殊箔）",
                "打凹凸",
                "上膜 (霧膜/亮膜)",
                "壓線",
                "導圓角(導圓尺寸5R)",
                "導圓角(導圓尺寸7.5R)",
                "軋型刀模",
                "其他加工(自填)",
                "局部光",
                "上水光",
                "自訂加工",
              ],
              default: "燙箔 (燙金/燙銀/特殊箔）",
            },
            length: {
              title: "長",
              type: "integer",
              default: 0,
              unit: "mm",
              pattern: "^\\d*$",
            },
            width: {
              title: "寬",
              type: "integer",
              default: 0,
              unit: "mm",
              pattern: "^\\d*$",
            },
            note: {
              title: "加工備註",
              type: "string",
            },
          },
        },
      },
      attachment: {
        title: "上傳檔案",
        type: "string",
      },
      quantity: {
        title: "製作數量",
        type: "number",
        default: 1,
        unit: "份",
      },
      budget: {
        title: "製作預算",
        type: "string",
        minLength: 1,
      },
      expect_time: {
        title: "預計收到成品時間",
        type: "string",
      },
      note: {
        title: "製作備註",
        type: "string",
      },

      // quote_way: {
      //   title: "報價方式",
      //   type: "string",
      //   enum: ["想先簡單了解報價", "需索取正式報價單"],
      // },
    },
  },
  uiSchema: {
    "ui:FieldTemplate": NotFieldTemplate,
    "ui:options": {
      label: false,
    },
    side: {
      "ui:placeholder": "單雙面",
    },
    size: {
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      type: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
      detail: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:field": "SizeField",
      },
      name: {
        "ui:FieldTemplate": NotFieldTemplate,
      },
    },
    print_method: {
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      name: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:help":
          "獨立版（開版印刷）/ 數位印刷（免開版）/ 藝術直噴（UV直噴）/ 網版印刷／大圖輸出請選擇「其他品項」",
      },
      detail: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:widget": "VarietyWidget",
        "ui:placeholder": "自定色數",
        type: "radio",
      },
      material: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:placeholder": "紙材選擇",
      },
    },
    name: {
      "ui:placeholder": "名稱",
    },
    note: {
      "ui:placeholder":
        "若上列項目選單未能讓您詳盡說明製作需求，如：特殊材質選擇／書籍類特殊插頁需求／加工方式 / 款式等，皆請於該欄位填寫備註。",
      "ui:widget": "textarea",
      "ui:options": {
        rows: 3,
      },
    },
    quantity: {
      "ui:help":
        "請提供製作「總數」，若有不同款式需有個別指定數量，請於「製作備註」另外說明。",
    },
    expect_time: {
      "ui:widget": "date",
      "ui:help":
        "建議若為指定時間需使用，如活動、展覽等，請於備註另外說明活動舉辦時間。請注意！實際交期時間將依正式製作需求評估後確認！",
    },
    attachment: {
      "ui:widget": "file",
    },
    process: {
      items: {
        "ui:FieldTemplate": NotFieldTemplate,
        "ui:field": "ProcessField",
        name: {
          "ui:placeholder": "請選擇加工選項",
        },
        note: {
          "ui:placeholder": "加工備註：請輸入加工尺寸範圍或備註",
          "ui:help":
            "請填寫該加工面積尺寸及加工位置（建議可附上參考圖片），若您的加工範圍為印刷品整面（如上膜）則不用填寫尺寸。若燙箔已知燙箔色號請填寫加工備註；壓線建議可備註印件展開及完成尺寸以供確認。若導圓角不需填寫尺寸。",
        },
      },
    },
    quote_way: {
      "ui:widget": "radio",
    },
    budget: {
      "ui:placeholder": "請輸入您的預計製作預算",
      "ui:help":
        "若為單件預算請寫「單件預算 / 單位」；若為總預算請寫「總預算：」。",
    },
  },
};

export default spec;
