import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import styled from "styled-components";
import { withTheme } from "@rjsf/core";
import theme from "./JsonSchemaForm/theme";
import { Row, Col, Divider, Radio } from "antd";
import { Label, RadioBtn } from "./QuoteWidget";
import {
  UserFieldTemplate,
  NotFieldTemplate,
} from "./JsonSchemaForm/FieldTemplate";
import serialize from "./JsonSchemaForm/serialize";
const constants = require("../../constants");
import * as Theme from "../../Theme";

const Form = withTheme(theme);

const schema = {
  personal: {
    type: "object",
    required: ["name", "phone", "email"],
    properties: {
      name: {
        title: "聯絡人名*",
        type: "string",
        minLength: 1,
        default: "",
      },
      phone: {
        title: "手機號碼*",
        type: "string",
        minLength: 10,
        pattern: "^([0|+[0-9]{1,5})?([7-9][0-9]{8})$",
      },
      email: {
        title: "信箱*",
        type: "string",
        minLength: 10,
        pattern: "^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$",
      },
      tel: {
        title: "聯絡市話",
        type: "string",
        default: "",
      },
      address: {
        type: "object",
        title: "聯絡地址",
        properties: {
          address: {
            title: "地址",
            type: "string",
            minLength: 1,
          },
          county: {
            title: "縣市",
            type: "string",
          },
          town: {
            title: "鄉鎮市區",
            type: "string",
          },
          zipcode: {
            title: "郵遞區號",
            type: "string",
          },
        },
      },
      fb_name: {
        title: "FB 名稱",
        type: "string",
      },
      line_id: {
        title: "LINE ID",
        type: "string",
      },
      note: {
        title: "說明備註",
        type: "string",
      },
    },
  },
  company: {
    type: "object",
    required: ["name", "phone", "email", "title", "gui_number"],
    properties: {
      title: {
        title: "公司行號*",
        type: "string",
        minLength: 1,
      },
      gui_number: {
        title: "統一編號*",
        type: "string",
        minLength: 8,
        pattern: "^[0-9]{8}$",
      },
      name: {
        title: "聯絡人名*",
        type: "string",
        minLength: 1,
        default: "",
      },
      position: {
        title: "單位職稱",
        type: "string",
      },
      phone: {
        title: "手機號碼*",
        type: "string",
        minLength: 10,
        pattern: "^([0|+[0-9]{1,5})?([7-9][0-9]{8})$",
      },
      email: {
        title: "信箱*",
        type: "string",
        minLength: 10,
        pattern: "^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$",
      },
      tel: {
        title: "聯絡市話",
        type: "string",
        default: "",
      },
      address: {
        type: "object",
        title: "聯絡地址",
        properties: {
          address: {
            title: "地址",
            type: "string",
          },
          county: {
            title: "縣市",
            type: "string",
          },
          town: {
            title: "鄉鎮市區",
            type: "string",
          },
          zipcode: {
            title: "郵遞區號",
            type: "string",
          },
        },
      },
      fb_name: {
        title: "FB 名稱",
        type: "string",
      },
      line_id: {
        title: "LINE ID",
        type: "string",
      },
      note: {
        title: "說明備註",
        type: "string",
      },
    },
  },
  organization: {
    type: "object",
    required: ["name", "phone", "email", "title", "gui_number"],
    properties: {
      title: {
        title: "單位名稱*",
        type: "string",
        minLength: 1,
      },
      gui_number: {
        title: "統一編號*",
        type: "string",
        minLength: 8,
        pattern: "^[0-9]{8}$",
      },
      name: {
        title: "聯絡人名*",
        type: "string",
        minLength: 1,
        default: "",
      },
      position: {
        title: "單位職稱",
        type: "string",
      },
      phone: {
        title: "手機號碼*",
        type: "string",
        minLength: 10,
        pattern: "^([0|+[0-9]{1,5})?([7-9][0-9]{8})$",
      },
      email: {
        title: "信箱*",
        type: "string",
        minLength: 1,
        pattern: "^[a-zA-Z0-9_!#$%&’*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$",
      },
      tel: {
        title: "聯絡市話",
        type: "string",
        default: "",
      },
      address: {
        type: "object",
        title: "聯絡地址",
        properties: {
          address: {
            title: "地址",
            type: "string",
          },
          county: {
            title: "縣市",
            type: "string",
          },
          town: {
            title: "鄉鎮市區",
            type: "string",
          },
          zipcode: {
            title: "郵遞區號",
            type: "string",
          },
        },
      },
      fb_name: {
        title: "FB 名稱",
        type: "string",
      },
      line_id: {
        title: "LINE ID",
        type: "string",
      },
      note: {
        title: "說明備註",
        type: "string",
      },
    },
  },
};

const uiSchema = {
  "ui:FieldTemplate": NotFieldTemplate,
  "ui:options": {
    label: false,
  },
  name: {
    "ui:placeholder": "聯絡人名",
    "ui:help": "請留下填寫印件諮詢單本人之聯繫方式。",
  },
  note: {
    "ui:placeholder": "聯絡人說明備註",
    "ui:widget": "textarea",
    "ui:options": {
      rows: 5,
    },
  },

  address: {
    "ui:field": "AddressInput",
  },
  phone: {},
  tel: {},
  email: {},

  title: {},

  gui_number: {},

  position: {
    "ui:placeholder": "單位職稱",
  },
  fb_name: {
    "ui:placeholder": "Facebook帳號",
  },
  line_id: {
    "ui:placeholder": "LINE ID",
  },
};

export const USER_TYPE = {
  personal: "personal",
  company: "company",
  organization: "organization",
};

export default function UserSection(props) {
  const [key, setKey] = useState(Date.now());
  const {
    type,
    setUserType,
    user,
    setUser,
    onRef,
    onError,
    setContent,
  } = props;

  return (
    <Wrapper>
      <Row>
        <Col sm={24} md={10} lg={9} xl={7}>
          <div className="left-section">
            <Label>聯絡單位</Label>
            <Radio.Group
              onChange={e => {
                let value = e.target.value;
                setUserType(value);
              }}
              value={type}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {Object.keys(USER_TYPE).map(key => (
                <RadioBtn value={key} key={key} style={{ marginBottom: 20 }}>
                  {
                    {
                      personal: "個人/設計師",
                      company: "公司行號",
                      organization: "學校/行政單位",
                    }[key]
                  }
                </RadioBtn>
              ))}
            </Radio.Group>
          </div>
        </Col>
        <Col xs={0} md={1} style={{ textAlign: "center" }}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col sm={24} md={13} lg={14} xl={16}>
          <h3>聯絡人資料</h3>
          <Divider />
          <Form
            key={key}
            ref={onRef}
            formData={user}
            schema={schema[type]}
            uiSchema={uiSchema}
            onChange={({ formData }) => {
              setUser(formData);
              // console.log("user changed", formData);
            }}
            onSubmit={({ formData }) => {
              console.log("user submited", formData);
              setKey(Date.now());
              setContent(prev => ({
                ...prev,
                user: serialize(formData, schema[type]),
              }));
            }}
            onError={errors => {
              onError(errors);
              console.warn("errors", errors);
            }}
            FieldTemplate={UserFieldTemplate}
            showErrorList={constants.debug}
          >
            <button
              type="submit"
              style={{ display: constants.debug ? "inline" : "none" }}
            >
              測試
            </button>
          </Form>

          {constants.debug && (
            <Row>
              <Label style={{ marginTop: 0 }}>會員資訊</Label>
              <p style={{ color: "#707070", whiteSpace: "break-spaces" }}>
                {serialize(user, schema[type])}
              </p>
            </Row>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 30px;

  & .left-section {
    display: flex;
  }

  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 0;

    & .left-section {
      flex-direction: column;
    }
  }
`;
