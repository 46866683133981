import React from "react";
import styled from "styled-components";
import * as Theme from "../../Theme";
import {
  Button,
  Checkbox as AntCheckbox,
  Input as AntInput,
  Radio,
  Select as AntSelect,
  Tooltip,
} from "antd";

import CaretDownOutlined from "@ant-design/icons/CaretDownOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import MinusOutlined from "@ant-design/icons/MinusOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import WarningOutlined from "@ant-design/icons/WarningOutlined";

const Title = styled.h4`
  background-color: ${Theme.colors.darkgrey};
  color: white;
  text-align: center;
  font-weight: 500;
  padding: 10px;
  margin-bottom: 40px;
`;

const Tab = styled.button`
  height: 140px;
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.selected ? "#CADBDB" : Theme.colors.sub)};
  border: 0;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  color: ${Theme.colors.text};
  transition: background-color ease 0.2s;

  & > img {
    width: 80px;
    margin-bottom: 10px;
  }

  &:hover,
  :active {
    background-color: #cadbdb;
  }
`;

const Option = styled.div``;

const Checkbox = styled(AntCheckbox)`
  /* TODO: how to change hover border color when checked ? */
  .ant-checkbox-checked :hover {
    /* it isn't work */
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Theme.colors.main};
    border-color: ${Theme.colors.main};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${Theme.colors.main};
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px;
`;

const Label = styled.div`
  flex: 0 0 ${props => props.width || 80}px;
  color: ${Theme.colors.text};
  margin-top: 14px;
  margin-right: 20px;
  text-align: ${props => props.align || "left"};

  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    flex: 1 0 100%;
    margin: 0 0 5px 0;
    text-align: left;
  }
`;

const Input = styled(AntInput)`
  padding: 8px 16px;
  border: none;
  background-color: ${Theme.colors.sub};
  margin: 6px 0px;
  flex-basis: 220px;
  flex-shrink: 0;
  ${props => (props.type === "short" ? "flex-basis: 90px; width: 90px;" : "")}
  ${props => (props.type === "long" ? "flex-basis: 320px;" : "")}

  @media only screen and  (max-width: ${Theme.breakpoints.xs}px) {
    flex-basis: ${props =>
      props.type === "long" ? "220px" : props.type === "short" ? "90px" : ""};
    flex-shrink: 1;
  }

  :hover {
    border-color: ${Theme.colors.main}4;
  }

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  /* ::after {
    border-color: green;
    background: green;
} */

`;

const TextArea = styled(AntInput.TextArea)`
  padding: 8px 16px;
  border: none;
  background-color: ${Theme.colors.sub};
  margin: 6px 0px;
  flex: 1;

  :hover {
    border-color: ${Theme.colors.main}4;
  }

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
  }

  .ant-input::selection {
    border-color: green;
  }

  /* ::after {
    border-color: green;
    background: green;
} */
`;

const RadioBtn = styled(Radio)`
  border: 0.8px solid ${Theme.colors.lightGrey};
  padding: 13px 15px 13px 15px;
  margin-bottom: 8px;
  /* width: 250px; */
  background-color: ${Theme.colors.sub}; 
  font-size: 14px;
  color: ${Theme.colors.text};
  align-items: center;

  & > .ant-radio {
    top: 0;
  }

  .ant-radio-inner {
    width: 18px;
    height: 18px;
  }
  .ant-radio-checked .ant-radio-inner {
    color: ${Theme.colors.main};
    /* border: 1.5px solid ${Theme.colors.darkgrey}; */
    /* -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2); */
    
  }
  .ant-radio-inner::after {
    background-color: transparent;
    color: ${Theme.colors.main};
    /* -webkit-box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2);
    box-shadow: 0 0 0 2px rgba(40, 166, 137, 0.2); */
    content: '✔';
    top: -3px;
    left: 3px;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${Theme.colors.main};
    color: ${Theme.colors.main};
  }

  & span.ant-radio + * {
    flex: 1;
    padding-left: 12px;
  }
`;

const Select = styled(AntSelect).attrs({
  suffixIcon: <CaretDownOutlined style={{ fontSize: 14, color: "#707070" }} />,
  bordered: false,
  dropdownMatchSelectWidth: false,
})`
  flex-basis: 150px;
  padding: 3px 0;
  margin: 6px 0px;
  border-bottom: solid 1px #707070;
  background-color: ${Theme.colors.sub};

  :focus {
    border-color: ${Theme.colors.main};
    outline-color: ${Theme.colors.main}4;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
    box-shadow: 0 0 0 2px rgba(100, 100, 100, 0.2);
  }
`;

const SelectOption = styled(AntSelect.Option)`
  width: 150px;
`;

const SquareButton = styled(Button)`
  border: solid 1px #707070;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f6f6f6;
  }
`;

function Plus(props) {
  return (
    <SquareButton {...props}>
      <PlusOutlined style={{ fontSize: 18, color: "#707070" }} />
    </SquareButton>
  );
}

function Minus(props) {
  return (
    <SquareButton {...props}>
      <MinusOutlined style={{ fontSize: 18, color: "#707070" }} />
    </SquareButton>
  );
}

function Hint({ children }) {
  if (children) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "6px 0",
          padding: 8,
        }}
      >
        <ExclamationCircleOutlined
          style={{ color: Theme.colors.brown, fontSize: 20, marginRight: 7 }}
        />
        <Tooltip
          title={children}
          color="#fff7d0"
          overlayInnerStyle={{ color: "#505050", padding: 12 }}
          placement="bottom"
        >
          <div
            style={{
              color: Theme.colors.brown,
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
          >
            欄位說明
          </div>
        </Tooltip>
      </div>
    );
  }
  return null;
}

function Warning({ children }) {
  if (children) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "6px 0",
          padding: 8,
        }}
      >
        <WarningOutlined
          style={{ color: Theme.colors.red, fontSize: 20, marginRight: 7 }}
        />
        <div
          style={{
            color: Theme.colors.red,
          }}
        >
          {children}
        </div>
      </div>
    );
  }
  return null;
}

function SizeText({ data, onChange, name }) {
  return (
    <input
      value={data[name]}
      type="number"
      onChange={e => {
        let value = Number(e.target.value);
        onChange({ ...data, [name]: value });
      }}
      style={{
        border: 0,
        borderBottom: "solid 1px #707070",
        outline: 0,
        margin: 6,
        padding: "0px 6px",
        width: 80,
        textAlign: "center",
      }}
    />
  );
}

function Size({ data, onChange, options = {}, units = {} }) {
  const { label, length, width, height } = options;

  return (
    <Row
      style={{
        margin: "8px 0px",
        alignItems: "center",
        color: "#707070",
        whiteSpace: "nowrap",
      }}
    >
      {label && <div>{label}： </div>}

      {length && (
        <React.Fragment>
          <div>長</div>
          <SizeText name="length" data={data} onChange={onChange} />
          <div style={{ margin: "0 5px" }}>{units["length"]}</div>
        </React.Fragment>
      )}

      {length && width && <div style={{ margin: "0 5px" }}>X</div>}

      {width && (
        <React.Fragment>
          <div style={{ margin: "0 5px" }}>寬</div>
          <SizeText name="width" data={data} onChange={onChange} />
          <div style={{ margin: "0 5px" }}>{units["width"]}</div>
        </React.Fragment>
      )}

      {(length || width) && height && <div style={{ margin: "0 5px" }}>X</div>}

      {height && (
        <React.Fragment>
          <div style={{ margin: "0 5px" }}> 高 </div>
          <SizeText name="height" data={data} onChange={onChange} />
          <div style={{ margin: "0 5px" }}> {units["height"]}</div>
        </React.Fragment>
      )}
    </Row>
  );
}

export {
  Title,
  Tab,
  Option,
  Checkbox,
  Row,
  Label,
  Input,
  TextArea,
  RadioBtn,
  Select,
  SelectOption,
  Plus,
  Minus,
  Hint,
  Warning,
  Size,
};
